
const name = 'module-stage'

export default class StageModule {
  constructor ($el) {
    this.$el = $el

    const $action = $el.querySelector(`.${name}__action-open`)
    $action.onclick = this.actionDidClick.bind(this)
  }

  actionDidClick (evt) {
    evt.preventDefault()
    window.scrollTo(0, window.innerHeight)
  }
}
