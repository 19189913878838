
const name = 'footer'
const visibleScrollY = 200

export default class FooterComponent {
  constructor ($el) {
    this.$el = $el
    this.visible = false

    // Bind to scroll
    window.addEventListener('scroll', this.windowDidScroll.bind(this))
    this.windowDidScroll()
  }

  windowDidScroll () {
    const scrollY = window.scrollY
    this.setVisible(scrollY >= visibleScrollY)
  }

  setVisible (visible) {
    if (this.visible !== visible) {
      this.visible = visible
      if (visible) {
        this.$el.classList.add(`${name}--visible`)
      } else {
        this.$el.classList.remove(`${name}--visible`)
      }
    }
  }
}
