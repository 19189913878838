
import { detect } from 'detect-browser'
import { register } from './lib/views'

import FooterComponent from './components/footer'
import StageModule from './modules/stage'

// Add browser and version as an html class, makes browser sass mixin work
const browser = detect()
if (browser) {
  const $html = document.querySelector('html')
  $html.classList.add(`browser--${browser.name}-${browser.version}`)
}

function registerViews () {
  // Register component views
  register('footer', FooterComponent)

  // Register module views
  register('module-stage', StageModule)
}

if (document.readyState !== 'loading') {
  // Dom loaded event already fired
  registerViews()
} else {
  // Wait for the dom to load
  document.addEventListener('DOMContentLoaded', registerViews)
}
